import React, { Component, useState } from 'react';
import { array, arrayOf, func, node, number, object, oneOf, string } from 'prop-types';
import classNames from 'classnames';

import { injectIntl, intlShape } from '../../../util/reactIntl';
import { parseSelectFilterOptions } from '../../../util/search';
import {
  SCHEMA_TYPE_ENUM,
  SCHEMA_TYPE_MULTI_ENUM,
  SCHEMA_TYPE_NESTED_ENUM,
} from '../../../util/types';

import { FieldCheckbox } from '../../../components';
import IconPlus from '../IconPlus/IconPlus';

import FilterPlain from '../FilterPlain/FilterPlain';
import FilterPopup from '../FilterPopup/FilterPopup';

import css from './SelectGroupMultipleFilter.module.css';

// SelectMultipleFilter doesn't need array mutators since it doesn't require validation.
// TODO: Live edit didn't work with FieldCheckboxGroup
//       There's a mutation problem: formstate.dirty is not reliable with it.
const GroupOfFieldCheckboxes = props => {
  const [openFilters, setOpenFilters] = useState([]);
  const { id, className, name, subkey, options } = props;

  const toggleIsOpen = (filterId) => {
    if (openFilters.includes(filterId)) {
      setOpenFilters(openFilters.filter(o => o !== filterId));
    } else {
      setOpenFilters([...openFilters, filterId]);
    }
  }
  
  return (
    <fieldset className={className}>
      <div className={css.list}>
        {options.map((opt) => {
          const fieldId = `${id}.${opt.parentKey}`;
          return (
            <div key={fieldId} className={css.groupContainer}>
              <div className={css.groupLabel} onClick={() => toggleIsOpen(opt.parentKey)}>
                <span className={css.label}>
                  {opt.parentKey.toUpperCase()}
                </span>
                <span className={css.openSign}>
                  <IconPlus isOpen={openFilters.includes(opt.parentKey)} isSelected={false} />
                </span>
              </div>
              <div className={classNames(css.groupItem, {[css.isOpen]: openFilters.includes(opt.parentKey)})}>
                {opt.options.map((option) => {
                  if (option?.options?.length > 0) {
                    const fieldId = `${id}.${opt.parentKey}.${option.option}`;
                    return (
                      <div key={fieldId} className={css.subGroupContainer}>
                        <div className={css.subGroupLabel}>{option.label}</div>
                        <div className={css.subGroupItem}>
                          {option.options.map((group, index) => {
                            const groupFieldId = `${id}.${opt.parentKey}.${group.value}`;
                            return (
                              <div key={group.value} className={css.item}>
                                <FieldCheckbox
                                  id={groupFieldId}
                                  name={subkey}
                                  label={group.label}
                                  value={group.value}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  } else {
                    const subFieldId = `${id}.${opt.parentKey}.${option.option}`;
                    return (
                      <div key={option.option} className={css.item}>
                        <FieldCheckbox
                          id={subFieldId}
                          name={name}
                          label={option.label}
                          value={option.option}
                        />
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          );
        })}
      </div>
    </fieldset>
  );
};

const getQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
};

// Format URI component's query param: { pub_key: 'has_all:a,b,c' }
const format = (selectedOptions, queryParamName, schemaType, searchMode) => {
  const hasOptionsSelected = selectedOptions && selectedOptions.length > 0;
  // const mode = schemaType === SCHEMA_TYPE_NESTED_ENUM && searchMode ? `${searchMode}:` : '';
  // const value = hasOptionsSelected ? `${mode}${selectedOptions.join(',')}` : null;
  const value = hasOptionsSelected ? `${selectedOptions.join(',')}` : null;
  return { [queryParamName]: value };
};

class SelectMultipleFilter extends Component {
  constructor(props) {
    super(props);

    this.filter = null;
    this.filterContent = null;

    this.positionStyleForContent = this.positionStyleForContent.bind(this);
  }

  positionStyleForContent() {
    if (this.filter && this.filterContent) {
      // Render the filter content to the right from the menu
      // unless there's no space in which case it is rendered
      // to the left
      const distanceToRight = window.innerWidth - this.filter.getBoundingClientRect().right;
      const labelWidth = this.filter.offsetWidth;
      const contentWidth = this.filterContent.offsetWidth;
      const contentWidthBiggerThanLabel = contentWidth - labelWidth;
      const renderToRight = distanceToRight > contentWidthBiggerThanLabel;
      const contentPlacementOffset = this.props.contentPlacementOffset;

      const offset = renderToRight
        ? { left: contentPlacementOffset }
        : { right: contentPlacementOffset };
      // set a min-width if the content is narrower than the label
      const minWidth = contentWidth < labelWidth ? { minWidth: labelWidth } : null;

      return { ...offset, ...minWidth };
    }
    return {};
  }

  render() {
    const {
      rootClassName,
      className,
      id,
      name,
      subkey,
      label,
      options,
      initialValues,
      contentPlacementOffset,
      onSubmit,
      queryParamNames,
      queryNestedParamNames,
      schemaType,
      searchMode,
      intl,
      showAsPopup,
      ...rest
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);

    const queryParamName = getQueryParamName(queryParamNames);
    const queryNestedParamName = getQueryParamName(queryNestedParamNames);
    const hasInitialValues = !!initialValues && !!initialValues[queryParamName];
    const hasNestedInitialValues = !!initialValues && !!initialValues[queryNestedParamName];
    // Parse options from param strings like "has_all:a,b,c" or "a,b,c"
    const selectedOptions = hasInitialValues
      ? parseSelectFilterOptions(initialValues[queryParamName])
      : [];
    const nestedSelectedOptions = hasNestedInitialValues
      ? parseSelectFilterOptions(initialValues[queryNestedParamName])
      : [];

    const labelForPopup = hasInitialValues || hasNestedInitialValues
      ? intl.formatMessage(
          { id: 'SelectMultipleFilter.labelSelected' },
          { labelText: label, count: selectedOptions.length + nestedSelectedOptions.length }
        )
      : label;

    const labelSelectionForPlain = hasInitialValues || hasNestedInitialValues
      ? intl.formatMessage(
          { id: 'SelectMultipleFilterPlainForm.labelSelected' },
          { count: selectedOptions.length + nestedSelectedOptions.length }
        )
      : '';

    const contentStyle = this.positionStyleForContent();

    // pass the initial values with the name key so that
    // they can be passed to the correct field
    const namedInitialValues = { [name]: selectedOptions, [subkey]: nestedSelectedOptions };
    const handleSubmit = values => {
      const usedValue = values ? values[name] : values;
      const usedSubValue = values ? values[subkey] : values;
      const frmatedValues = format(usedValue, queryParamName, schemaType, searchMode)
      const frmatedSubValues = format(usedSubValue, queryNestedParamName, schemaType, searchMode)
      onSubmit({...frmatedValues, ...frmatedSubValues});
    };

    return showAsPopup ? (
      <FilterPopup
        className={classes}
        rootClassName={rootClassName}
        popupClassName={css.popupSize}
        name={name}
        label={labelForPopup}
        isSelected={hasInitialValues}
        id={`${id}.popup`}
        showAsPopup
        contentPlacementOffset={contentPlacementOffset}
        onSubmit={handleSubmit}
        initialValues={namedInitialValues}
        keepDirtyOnReinitialize
        {...rest}
      >
        <GroupOfFieldCheckboxes
          className={css.fieldGroup}
          name={name}
          subkey={subkey}
          id={`${id}-checkbox-group`}
          options={options}
        />
      </FilterPopup>
    ) : (
      <FilterPlain
        className={className}
        rootClassName={rootClassName}
        label={label}
        labelSelection={labelSelectionForPlain}
        isSelected={hasInitialValues}
        id={`${id}.plain`}
        liveEdit
        contentPlacementOffset={contentStyle}
        onSubmit={handleSubmit}
        initialValues={namedInitialValues}
        {...rest}
      >
        <GroupOfFieldCheckboxes
          className={css.fieldGroupPlain}
          name={name}
          subkey={subkey}
          id={`${id}-checkbox-group`}
          options={options}
        />
      </FilterPlain>
    );
  }
}

SelectMultipleFilter.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  contentPlacementOffset: 0,
  searchMode: null,
};

SelectMultipleFilter.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  name: string.isRequired,
  queryParamNames: arrayOf(string).isRequired,
  label: node.isRequired,
  onSubmit: func.isRequired,
  options: array.isRequired,
  searchMode: oneOf(['has_all', 'has_any']),
  schemaType: oneOf([SCHEMA_TYPE_ENUM, SCHEMA_TYPE_MULTI_ENUM, SCHEMA_TYPE_NESTED_ENUM]).isRequired,
  initialValues: object,
  contentPlacementOffset: number,

  // form injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(SelectMultipleFilter);
